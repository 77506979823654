import { find, get, isNil, orderBy } from 'lodash';
import { filterNullMonths, filterCurrentMonths } from './chartUtility';

class EnrolmentCurveAndTable {
  constructor() {
    this._enrolmentData = [];
    this._siteActivationData = [];
    this._forecastOverTime = [];
    this._probabilityRange = [];
    this._forecastMetrics = [];
    this._forecastLSIDate = null;
    this._plannedLSIDate = null;
    this._forecastLSI10thPercentile = null;
    this._forecastLSI99thPercentile = null;
    this._forecastLSILowerBound = null;
    this._forecastLSIUpperBound = null;
    this._forecastLSIConfidenceInterval = null;
    this._screeningData = [];
    this._dropoutData = [];
  }

  set forecastLSILowerBound(value) {
    this._forecastLSILowerBound = value;
  }

  get forecastLSILowerBound() {
    return this._forecastLSILowerBound;
  }

  set forecastLSIUpperBound(value) {
    this._forecastLSIUpperBound = value;
  }

  get forecastLSIUpperBound() {
    return this.__forecastLSIUpperBound;
  }

  set forecastLSIConfidenceInterval(value) {
    this._forecastLSIConfidenceInterval = value;
  }

  get forecastLSIConfidenceInterval() {
    return this._forecastLSIConfidenceInterval;
  }

  set enrolmentData(dataItems) {
    const sortedDataItems = orderBy(dataItems, ['trialMonth'], ['asc']);
    const filteredDataItems = filterNullMonths(sortedDataItems);
    this._enrolmentData = filteredDataItems.map((dataItem) => {
      const cleanedDataItems = this._cleanTrialDataDates(dataItem);
      const returnData = { ...cleanedDataItems, isEnrollmentApi: true };
      return returnData;
    });
  }

  get enrolmentData() {
    return this._enrolmentData;
  }

  set siteActivationData(dataItems) {
    const sortedDataItems = orderBy(dataItems, ['trialMonth'], ['asc']);

    const filteredDataItems = filterNullMonths(sortedDataItems);
    this._siteActivationData = filteredDataItems.map((dataItem) => {
      const cleanedDataItems = this._cleanTrialDataDates(dataItem);
      const returnData = { ...cleanedDataItems, isActivationApi: true };
      return returnData;
    });
  }

  get siteActivationData() {
    return this._siteActivationData;
  }

  set forecastOverTime(dataItems) {
    const sortedDataItems = orderBy(dataItems, ['trialMonth'], ['asc']);
    const filteredDataItems = filterNullMonths(sortedDataItems);
    const filteredMonthwise = filterCurrentMonths(filteredDataItems);
    this._forecastOverTime = filteredMonthwise.map((dataItem, index) => {
      const cleanedDataItems = this._cleanTrialDataDates(dataItem);
      const isArtificialPoint = index === 0;
      const returnData = {
        ...cleanedDataItems,
        isForecastApi: !isArtificialPoint,
      };
      return returnData;
    });
  }

  get forecastOverTime() {
    return this._forecastOverTime;
  }

  set probabilityRange(dataItems) {
    const sortedDataItems = orderBy(dataItems, ['trialMonth'], ['asc']);
    const filteredDataItems = filterNullMonths(sortedDataItems);
    const filteredMonthwise = filterCurrentMonths(filteredDataItems);
    const transformed = filteredMonthwise.map((dataItem) => {
      const cleanedDataItems = this._cleanTrialDataDates(dataItem);
      const returnData = {
        ...cleanedDataItems,
        to: get(cleanedDataItems, 'forecastEnrollmentLowerQuartile'),
        from: get(cleanedDataItems, 'forecastEnrollmentUpperQuartile'),
        lowerBound: this._forecastLSILowerBound,
        upperBound: this._forecastLSIUpperBound,
        forecastConfidenceInterval: this._forecastLSIConfidenceInterval,
        isRange: true,
      };
      return returnData;
    });
    this._probabilityRange = transformed;
  }

  get probabilityRange() {
    return this._probabilityRange;
  }

  set forecastMetrics(dataItems) {
    this._forecastMetrics = dataItems;

    const forecastLSI10thPercentileKey = 'FORECAST_LSI_10_PERCENTILE';
    this._forecastLSI10thPercentile = find(this._forecastMetrics, [
      'metric',
      forecastLSI10thPercentileKey,
    ]);

    const forecastLSI99thPercentileKey = 'FORECAST_LSI_99_PERCENTILE';
    this._forecastLSI10thPercentile = find(this._forecastMetrics, [
      'metric',
      forecastLSI99thPercentileKey,
    ]);

    const forecastLSIKey = 'FORECAST_LSI';
    const plannedLSIKey = 'PLANNED_LSI';
    this._forecastLSIDate = find(this._forecastMetrics, [
      'metric',
      forecastLSIKey,
    ]);
    this._plannedLSIDate = find(this._forecastMetrics, [
      'metric',
      plannedLSIKey,
    ]);
  }

  get forecastMetrics() {
    return this._forecastMetrics;
  }

  set screeningData(dataItems) {
    const sortedDataItems = orderBy(dataItems, ['trialMonth'], ['asc']);
    const filteredDataItems = filterNullMonths(sortedDataItems);

    this._screeningData = filteredDataItems.map((dataItem) => {
      const cleanedDataItems = this._cleanTrialDataDates(dataItem);
      const returnData = { ...cleanedDataItems, isEnrollmentApi: true };
      return returnData;
    });
  }

  get screeningData() {
    return this._screeningData;
  }

  set dropoutData(dataItems) {
    const sortedDataItems = orderBy(dataItems, ['trialMonth'], ['asc']);

    const filteredDataItems = filterNullMonths(sortedDataItems);
    this._dropoutData = filteredDataItems.map((dataItem) => {
      const cleanedDataItems = this._cleanTrialDataDates(dataItem);
      const returnData = { ...cleanedDataItems, isEnrollmentApi: true };
      return returnData;
    });
  }

  get dropoutData() {
    return this._dropoutData;
  }

  _cleanTrialDataDates(dataItem) {
    let { trialMonth } = dataItem;
    if (!isNil(trialMonth)) {
      const trialMonthParts = trialMonth.split('-');
      trialMonthParts[2] = '01';
      trialMonth = trialMonthParts.join('-');
    }
    return { ...dataItem, trialMonth };
  }
}
export default EnrolmentCurveAndTable;
