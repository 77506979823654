import { isNil } from 'lodash';
import dayjs from 'dayjs';

// Move this inside class
export const convertToArrayOfNumbers = (array) =>
  array.map((item) => (!isNil(item) ? Number(item) : item));

export const filterNullMonths = (dataItems) => {
  let filteredData = dataItems;
  if (Array.isArray(dataItems)) {
    filteredData = dataItems.filter((dataItem) => {
      const isValidMonth = !isNil(dataItem.trialMonth);
      return isValidMonth;
    });
  }
  return filteredData;
};

export const filterCurrentMonths = (dataItems) => {
  let filteredData = dataItems;
  if (Array.isArray(dataItems)) {
    filteredData = dataItems.filter((dataItem) => {
      const isValidMonth = dayjs().isSameOrBefore(
        dayjs(dataItem.trialMonth),
        'month',
      );
      return isValidMonth;
    });
  }
  return filteredData;
};
